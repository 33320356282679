import {ref} from '@vue/reactivity'
import {ResponseData, Search, Sorting} from '@/services/types'
import {AxiosError} from 'axios'
import {showError} from '@/composables/utils'
import {OrdersModels} from '@/composables/orders/types'
import {OrderByVariants} from '@/services/constants'
import {LimitPageDefault} from '@/composables/constants'
import OrdersService,{GetListParams as Params} from '@/services/OrdersService'
import moment from 'moment'


enum SearchName {
    FIO = 'ФИО',
    EMAIL = 'Почта',
    REFURL = 'Реф.метка',
    PROMOCODE = 'Промокод',
    PHONE = 'Телефон',
    INN = 'ИНН',
}

enum SearchParam {
    FIO = 'search_fio',
    EMAIL = 'search_email',
    REFURL = 'search_ref',
    PROMOCODE = 'search_promo',
    PHONE = 'search_phone',
    INN = 'search_inn',
}

const SearchVariants: Search[] = [
    {
        name: SearchName.FIO,
        param: SearchParam.FIO,
        value: null,
    },
    {
        name: SearchName.EMAIL,
        param: SearchParam.EMAIL,
        value: null,
    },
    {
        name: SearchName.REFURL,
        param: SearchParam.REFURL,
        value: null,
    },
    {
        name: SearchName.PROMOCODE,
        param: SearchParam.PROMOCODE,
        value: null,
    },
    {
        name: SearchName.PHONE,
        param: SearchParam.PHONE,
        value: null,
    },
    {
        name: SearchName.INN,
        param: SearchParam.INN,
        value: null,
    }
]

export const SearchDefault: Search = SearchVariants[0]

type Meta = {
    limit: number,
    offset: number,
    sort_by: string,
    order_by: OrderByVariants,
    total: number,
    page: {
        current: number,
        total: number,
    },
}

enum SortByVariants {
    CREATED_AT = 'created_at'
}


const SortingVariants: Sorting[] = [
    {
        name: 'Сначала новые',
        sort_by: SortByVariants.CREATED_AT,
        order_by: OrderByVariants.DESC,
    },
    {
        name: 'Сначала старые',
        sort_by: SortByVariants.CREATED_AT,
        order_by: OrderByVariants.ASC,
    }
]

export const SortingDefault: Sorting = SortingVariants[0]

const MetaDefault = {
    limit: LimitPageDefault.value,
    offset: 0,
    order_by: SortingDefault.order_by,
    sort_by: SortingDefault.sort_by,
    total: 0,
    page: {
        current: 1,
        total: 1,
    }
} as Meta



export function list() {
    const list = ref([] as OrdersModels[])
    const isLoading = ref(false)
    const filterAgentId = ref()
    const filterStatusId = ref()
    const filterExportedAtFrom = ref()
    const filterExportedAtTo = ref()
    const search = ref(SearchDefault)
    const searchFIO = ref()
    const searchEmail = ref()
    const searchRef = ref()
    const searchPromo = ref()
    const searchPhone = ref()
    const searchInn = ref()
    const sorting = ref(SortingDefault)
    const meta = ref(MetaDefault)

    function fetch(): void {

        const params = {
            limit: meta.value.limit,
            offset: meta.value.offset,
            order_by: meta.value.order_by,
            sort_by: meta.value.sort_by,
            agent_id: filterAgentId.value,
            status_id: filterStatusId.value,
            exported_at_from: filterExportedAtFrom.value ? moment(filterExportedAtFrom.value).format('YYYY-MM-DD 00:00:00'): null,
            exported_at_to: filterExportedAtTo.value ? moment(filterExportedAtTo.value).format('YYYY-MM-DD 23:59:59'): null,
            search_fio: searchFIO.value,
            search_email: searchEmail.value,
            search_ref: searchRef.value,
            search_promo: searchPromo.value,
            search_phone: searchPhone.value,
            search_inn: searchInn.value,
        } as Params

        isLoading.value = true
        OrdersService.getList(params)
            .then((response: ResponseData) => {
                list.value = response.data.data.items
                meta.value = response.data.data.meta
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isLoading.value = false)
    }

    function setPage(value: number) {
        meta.value.offset = (value - 1) * meta.value.limit
    }

    function setLimit(value: number) {
        meta.value.limit = value
    }

    function setSorting(value: Sorting) {
        sorting.value = value
        meta.value.sort_by = value.sort_by
        meta.value.order_by = value.order_by
    }

    function reset(){
        setFilterAgentId()
        setFilterStatusId()
        setFilterExportedAtFrom()
        setFilterExportedAtTo()
        setLimit(LimitPageDefault.value)
        setPage(1)
        setSorting(SortingDefault)

        setSearch(SearchDefault)
        setSearchFio()
        setSearchEmail()
        setSearchRef()
        setSearchPromo()
        setSearchPhone()
        setSearchInn()
    }


    function setFilterAgentId(id?: null|number) {
        filterAgentId.value = id
    }

    function setFilterStatusId(id?: null|number) {
        filterStatusId.value = id
    }

    function setFilterExportedAtFrom(id?: null|Date) {
        filterExportedAtFrom.value = id
    }
    function setFilterExportedAtTo(id?: null|Date) {
        filterExportedAtTo.value = id
    }

    function setSearch(value: Search) {
        search.value = value
    }

    function setSearchFio(value?: null | string) {
        searchFIO.value = value
    }

    function setSearchEmail(value?: null | string) {
        searchEmail.value = value
    }

    function setSearchRef(value?: null | string) {
        searchRef.value = value
    }

    function setSearchPromo(value?: null | string) {
        searchPromo.value = value
    }

    function setSearchPhone(value?: null | string) {
        searchPhone.value = value
    }

    function setSearchInn(value?: null | string) {
        searchInn.value = value
    }

    return {
        // getters
        list,
        isLoading,
        meta,
        sorting,
        sortingVariants: SortingVariants,
        filterStatusId,
        filterExportedAtFrom,
        filterExportedAtTo,
        searchVariants: SearchVariants,
        search,
        searchFIO,
        searchEmail,
        searchRef,
        searchPromo,
        searchPhone,
        searchInn,

        // mutations
        setFilterAgentId,
        setFilterStatusId,
        setFilterExportedAtFrom,
        setFilterExportedAtTo,
        setPage,
        setLimit,
        setSorting,
        setSearch,
        setSearchFio,
        setSearchEmail,
        setSearchRef,
        setSearchPromo,
        setSearchPhone,
        setSearchInn,
        reset,

        // actions
        fetch
    }
}
