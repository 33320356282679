
import {Options, setup, Vue} from 'vue-class-component'
import {item} from '@/composables/orders/item'
import {OrdersModels, OrderStatusId} from '@/composables/orders/types'
import useEmitter from '@/composables/useEmitter'


enum TabNames {
  GENERAL = 'general',
  STATUS = 'status',
  AGENT = 'agent',
  USER = 'user',
  ACTIONS = 'actions',
}

@Options({
  props: {
    order: {
      type: [Object, null]
    },
    onClose: Function
  },
  watch: {
    order(value) {
      this.model = value
      this.ctx.setEntity(value)
      this.tab = TabNames.ACTIONS
    }
  },
  methods: {
    close(): void {
      if (this.model !== this.ctx.entity) {
        this.ctx.emitter.emit('orders:refresh')
      }
      this.model = null
      this.onClose()
    }
  },
})
export default class ModalCard extends Vue {
  model: OrdersModels | null = null;
  ordersStatusId = OrderStatusId;
  tabNames = TabNames
  tab = TabNames.ACTIONS

  ctx = setup(() => {
    const {emitter} = useEmitter()
    return {emitter, ...item()}
  })

  canBeInProgress(): boolean {
    return this.ctx.entity.status.id === this.ordersStatusId.CREATED
  }

  canBePaid(): boolean {
    return !this.ctx.entity.status.is_lock && this.ctx.entity.status.id !== this.ordersStatusId.CREATED
  }

  canBeReverted(): boolean {
    return this.canBePaid()
  }

  canBeError(): boolean {
    return this.canBePaid()
  }

  orderHasAction(): boolean{
    return this.canBePaid() || this.canBeInProgress()
  }
}
