import {ref} from '@vue/reactivity'
import {AxiosError} from 'axios'
import {showError} from '@/composables/utils'
import OrdersService  from '@/services/OrdersService'
import {OrdersModels} from '@/composables/orders/types'
import { ResponseData } from '@/services/types'

export interface AgentItemInterface {
    // getters
    entity: OrdersModels;

    // mutators
    setEntity: (order: OrdersModels) => void

    // actions
    changeStatusOnProcess: () => void
    changeStatusOnPay: () => void
    changeStatusOnError: () => void
    changeStatusOnRevert: () => void
}

export function item() {
    const entity = ref({} as OrdersModels)
    const isEntityLoading = ref(false)

    function setEntity(order: OrdersModels){
        entity.value = order
    }

    function changeStatusOnProcess(): void {
        isEntityLoading.value = true
        OrdersService.process(entity.value.id)
            .then((response: ResponseData) => {
                entity.value = response.data.data
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isEntityLoading.value = false)
    }

    function changeStatusOnPay(): void {
        isEntityLoading.value = true
        OrdersService.pay(entity.value.id)
            .then((response: ResponseData) => {
                entity.value = response.data.data
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isEntityLoading.value = false)
    }

    function changeStatusOnError(): void {
        isEntityLoading.value = true
        OrdersService.error(entity.value.id)
            .then((response: ResponseData) => {
                entity.value = response.data.data
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isEntityLoading.value = false)
    }

    function changeStatusOnRevert(): void {
        isEntityLoading.value = true
        OrdersService.revert(entity.value.id)
            .then((response: ResponseData) => {
                entity.value = response.data.data
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isEntityLoading.value = false)
    }

    return {
        // getters
        entity,
        isEntityLoading,

        // mutations
        setEntity,

        // actions
        changeStatusOnProcess,
        changeStatusOnPay,
        changeStatusOnError,
        changeStatusOnRevert
    }
}
